import { Routes, Route } from 'react-router-dom';
import './App.css';
import AboutUs from "./pages/AboutUs"
import Toolbar from './component/toolbar';
import Solution from "./pages/Solution"
import Blogs from './pages/Blogs';
import SuccessStories from './pages/SuccessStories';
import FullBlog from './component/fullBlog';

function App() {
  return (
    <div>
      <Toolbar />
      <Routes>
        <Route path="/" exact element={<AboutUs />} />
        <Route path="/solutions" eaxct element={<Solution />} />
        <Route path='/blogs' exact element={<Blogs />} />
        <Route path='/success-stories' exact element={<SuccessStories />} />
        <Route path="/blogs/:id" exact element={<FullBlog />} />
      </Routes>
    </div>
  );
}

export default App;
