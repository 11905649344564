import React from "react";

class BlogCard extends React.Component {
    render() {
        return (
            <div className="box-shadow rounded">
                <img src={`https:${this.props.image}`} alt="blogs" className="image-mobile" style={{minWidth: "100%"}} />
                <div className="p-3">
                    <div className="dark fw-semibold fs-3 mb-2">{this.props.heading}</div>
                    <div className="light mb-3">{this.props.description}</div>
                    <div className="d-flex justify-content-end">
                        <a className="read-more" href={`/blogs/${this.props.id}`}>
                            Read More
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default BlogCard