import React from "react";
import Commas from "../assets/Commas.svg"
import InvertedCommas from "../assets/InvertedCommas.svg"

class AboutUs extends React.Component {
    render() {
        return (
            <div>
                <div className="about-bg d-flex justify-content-end align-items-center main-container">
                    <div className="col-lg-6 col-12">
                        <div className="text-white fw-bold lead-heading">Our Mission</div>
                        <div className="rounded p-4 bg-secondary-o white lh-lg justify text">
                            Our mission, #ObesityFreeIndia, aims to empower Indians around the world to
                            achieve and maintain optimal health and well-being through sustainable lifestyle changes,
                            personalised coaching, and a supportive community. We are dedicated to providing the knowledge,
                            tools, and encouragement necessary for everyone to take control of their health, overcome
                            lifestyle-related health issues, and live a fulfilling life.
                        </div>
                    </div>
                </div>
                <div className="section-change">
                    <div className="lead-heading dark fw-bold text-center mb-3">Our Vision</div>
                    <div className="d-flex flex-xl-row flex-sm-col justify-content-between align-items-center gap-5 main-container">
                        <div>
                            <img alt="vision" src={require("../assets/aboutus/Vision.png")} className="image-mobile" />
                        </div>
                        <div className="justify lh-lg text">
                            Our vision is to create a global movement where everyone has the
                            opportunity and resources to live a healthy, balanced, and fulfilling life.
                            We aim to be the leading authority in health coaching, known for our integrity,
                            compassion, and innovative approach. By fostering a culture of continuous learning,
                            support, and empowerment, we aspire to transform lives, inspire lasting change, and
                            build a healthier world for future generations.
                        </div>
                    </div>
                </div>
                <div className="section-change">
                    <div className="lead-heading dark fw-bold text-center mb-3">Our Values</div>
                    <div className="row row-cols-xl-3 row-cols-1 g-0">
                        <div className="col bg-dark-o">
                            <div className="p-6">
                                <div className="lead-heading light fw-bold mb-3">Health First</div>
                                <div className="justify light fs-4">
                                    We believe that maintaining good health is the foundation for a fulfilling life.
                                    Our approach prioritises you to get healthy first whereas fat loss is a byproduct of getting healthy.
                                </div>
                            </div>
                        </div>
                        <div className="col bg-tertiary-o">
                            <div className="p-6">
                                <div className="lead-heading primary fw-bold mb-3">Empowerment</div>
                                <div className="justify primary fs-4">
                                    We empower individuals with the knowledge they need to take
                                    control of their health and well-being. We aim to create self-reliant individuals
                                    who can maintain their health independently.
                                </div>
                            </div>
                        </div>
                        <div className="col bg-light-o">
                            <div className="p-6">
                                <div className="lead-heading dark fw-bold mb-3">Sustainability</div>
                                <div className="justify dark fs-4">
                                    We advocate for sustainable health practices that can be maintained for life.
                                    Quick fixes are not our approach; instead, we focus on long-term health benefits
                                    and realistic lifestyle adjustments.
                                </div>
                            </div>
                        </div>
                        <div className="col bg-primary-o">
                            <div className="p-6">
                                <div className="lead-heading tertiary fw-bold mb-3">Empathy</div>
                                <div className="justify tertiary fs-4">
                                    We listen actively and with empathy. We strive to understand our clients'
                                    perspectives and feelings, ensuring they feel heard and supported.
                                </div>
                            </div>
                        </div>
                        <div className="col bg-secondary-o">
                            <div className="p-6">
                                <div className="lead-heading tertiary fw-bold mb-3">Clarity</div>
                                <div className="justify tertiary fs-4">
                                    We listen actively and with empathy. We strive to understand our clients'
                                    perspectives and feelings, ensuring they feel heard and supported.
                                </div>
                            </div>
                        </div>
                        <div className="col bg-dark-o">
                            <div className="p-6">
                                <div className="lead-heading light fw-bold mb-3">Educational Focus</div>
                                <div className="justify light fs-4">
                                    We emphasise education in our communication.
                                    We provide our clients with the knowledge and information they need to make
                                    informed decisions about their health.
                                </div>
                            </div>
                        </div>
                        <div className="col bg-tertiary-o">
                            <div className="p-6">
                                <div className="lead-heading primary fw-bold mb-3">Confidentiality</div>
                                <div className="justify primary fs-4">
                                    We respect and protect our clients' privacy.
                                    All personal information and health data are handled
                                    with the utmost confidentiality.
                                </div>
                            </div>
                        </div>
                        <div className="col bg-light-o">
                            <div className="p-6">
                                <div className="lead-heading dark  fw-bold mb-3">Openness</div>
                                <div className="justify dark fs-4">
                                    We foster an environment where open communication is encouraged.
                                    Our clients can freely express their thoughts, concerns, and feedback.
                                </div>
                            </div>
                        </div>
                        <div className="col bg-primary-o">
                            <div className="p-6">
                                <div className="lead-heading tertiary fw-bold mb-3">Timeliness</div>
                                <div className="justify tertiary fs-4">
                                    We prioritise timely communication. We ensure our clients receive
                                    prompt responses to their questions and updates on their progress.
                                </div>
                            </div>
                        </div>
                        <div className="col bg-secondary-o">
                            <div className="p-6">
                                <div className="lead-heading tertiary fw-bold mb-3">Integrity</div>
                                <div className="justify tertiary fs-4">
                                    We uphold the highest standards of honesty and ethical behaviour
                                    in all our interactions. Our clients' trust is built on our
                                    consistent demonstration of integrity.
                                </div>
                            </div>
                        </div>
                        <div className="col bg-tertiary-o">
                            <div className="p-6">
                                <div className="lead-heading secondary fw-bold mb-3">Reliability</div>
                                <div className="justify secondary fs-4">
                                    We are dependable and consistent in our actions.
                                    Our clients can count on us to follow through on our
                                    commitments and deliver on our promises.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-change">
                    <div className="lead-heading dark fw-bold text-center mb-3">Our Story</div>
                    <div className="d-flex flex-lg-row flex-sm-col gap-5 justify-content-center main-container">
                        <div className="text">
                            <div className="mb-3">
                                <img src={InvertedCommas} alt="story" style={{ maxWidth: "5rem" }} />
                            </div>
                            <div className="mb-5">
                                I have been overweight since College Days. Late night sleeping ; Unhealthy Eating
                                Habits ( Eating Frequently/ Consuming Junk Food regularly / Consuming Excessive Carbs / Eating Food late night) and No Physical Activity was a daily routine. I never realised this could take a serious toll on my health.
                            </div>
                            <div className="mb-5">
                                This coupled with excessive amounts of stress of not being able to decide which career
                                to choose after graduation were the reasons because of which i was gaining weight rapidly.
                            </div>
                            <div className="mb-5">
                                Before I could understand anything, I had already gained over 10+ kgs.
                                Most importantly, my life was not in my control. That was the time I got to know about body
                                science through a ReshapeNation coach who guided me to lose 12 Kgs in around 70 days.
                            </div>
                            <div>
                                ~ Hitesh
                            </div>
                            <div className="d-flex justify-content-end">
                                <img src={Commas} alt="story" style={{ maxWidth: "5rem" }} />
                            </div>
                        </div>
                        <div>
                            <img src={require("../assets/aboutus/Story.png")} alt="story" className="image-mobile" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AboutUs