import React from "react"
import solutionData from "../data/solution.json"
import SolutionCard from "../component/solutionCard"
import JoinForm from "../component/joinForm"
import { Modal, ModalBody, ModalHeader } from "reactstrap"

class Solution extends React.Component {
    constructor() {
        super();
        this.state = {
            isOpen: false
        }
    }
    render() {
        return (
            <div>
                <div className="solution-bg d-flex align-items-center main-container">
                    <div className="text-white fw-bold lead-heading">Our Solutions</div>
                </div>
                <div className="section-change">
                    <div className="main-container">
                        <div className="row row-cols-1 row-cols-xl-2 g-5 mb-5">
                            {solutionData.solutionList.map((eachSolution, index) => {
                                return (
                                    <div className="col" key={index}>
                                        <SolutionCard key={index} heading={eachSolution.heading} description={eachSolution.description} image={eachSolution.image} />
                                    </div>
                                )
                            })}
                        </div>
                        <div className="d-flex justify-content-center">
                            <button onClick={() => this.setState({ isOpen: true })}>
                                Join Our Program
                            </button>
                        </div>
                    </div>
                </div>
                <Modal size="lg" isOpen={this.state.isOpen} toggle={() => this.setState({ isOpen: false })}>
                    <ModalHeader toggle={() => this.setState({ isOpen: false })}>Join Details</ModalHeader>
                    <ModalBody className="d-flex justify-content-center">
                        <JoinForm />
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default Solution