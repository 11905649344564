import React from "react";

class JoinForm extends React.Component {
    constructor() {
        super()
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            mobile: "",
            age: "",
            weight: "",
            solution: "",
            query: "",
            solutions: []
        }
    }
    render() {
        const onChange = (e) => {
            const { name, value } = e.target
            this.setState({ [name]: value })
        }
        const checkBoxChange = (e) => {
            const { checked, value } = e.target
            if (checked) {
                this.setState({ solutions: [...this.state.solutions, value] }, () => console.log(this.state.solutions))
            }
            else {
                this.setState({ solutions: this.state.solutions.filter(e => e !== value) })
            }
            console.log(e)
        }
        return (
            <div>
                <form method="POST" target="_blank" action="https://formsubmit.co/support@priyankahitesh.com">
                    <div className="row row-cols-xl-3 row-cols-1 g-3 mb-3">
                        <div className="col">
                            <input placeholder="First Name" onChange={onChange} value={this.state.firstName} name="firstName" className="mb-3" />
                        </div>
                        <div className="col">
                            <input placeholder="Last Name" onChange={onChange} value={this.state.lastName} name="lastName" className="mb-3" />
                        </div>
                        <div className="col">
                            <input placeholder="Email Id" onChange={onChange} value={this.state.email} name="email" className="mb-3" />
                        </div>
                        <div className="col">
                            <input placeholder="Whatapp Contact" onChange={onChange} value={this.state.mobile} name="mobile" className="mb-3" />
                        </div>
                        <div className="col">
                            <input placeholder="Age" onChange={onChange} value={this.state.age} name="age" className="mb-3" />
                        </div>
                        <div className="col">
                            <input placeholder="Weight (in kgs)" onChange={onChange} value={this.state.weight} name="weight" className="mb-3" />
                        </div>
                    </div>
                    <div>
                        <label>Which treatment do you want? </label>
                        <div className="d-flex">
                            <input className="form-check-input" type="checkbox" name="solutions" value="Weight Loss" id="flexCheckDefault" onChange={checkBoxChange} />
                            <label className="ms-3">Weight Loss</label>
                        </div>
                        <div className="d-flex">
                            <input className="form-check-input" type="checkbox" name="solutions" value="Diabetes" id="flexCheckDefault" onChange={checkBoxChange} />
                            <label className="ms-3">Diabetes</label>
                        </div>
                        <div className="d-flex">
                            <input className="form-check-input" type="checkbox" name="solutions" value="Thyroid Care" id="flexCheckDefault" onChange={checkBoxChange} />
                            <label className="ms-3">Thyroid Care</label>
                        </div>
                        <div className="d-flex">
                            <input className="form-check-input" type="checkbox" name="solutions" value="PCOS Care" id="flexCheckDefault" onChange={checkBoxChange} />
                            <label className="ms-3">PCOS Care</label>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <button type="submit" >
                            Submit Details
                        </button>
                    </div>
                </form>
            </div>

        )
    }
}

export default JoinForm