import React from "react";

class solutionCard extends React.Component {
    render() {
        return (
            <div className="p-3 d-flex flex-lg-row flex-sm-col gap-3 box-shadow rounded">
                <img src={require(`../assets/solutions${this.props.image}`)} alt="healthcare" />
                <div>
                    <div className="dark heading mb-2">{this.props.heading}</div>
                    <div className="light justify">
                        {this.props.description}
                    </div>
                </div>
            </div>
        )
    }
}

export default solutionCard