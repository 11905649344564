import React from "react";
import BlogCard from "../component/blogCard";
import { createClient } from "contentful";

const client = createClient({
    space: `pmtorbc7c54z`,
    accessToken: `SGpR3nWjHeWci9XBcY_VofutGefIHdxzvPdgOpt91N4`
})

class Blogs extends React.Component {
    constructor() {
        super()
        this.state = {
            allBlogs: []
        }
    }

    componentDidMount() {
        client.getEntries().then(response => {
            this.setState({ allBlogs: response.items })
        }).catch(err => console.log(err.message))
 
    }

    render() {
        return (
            <div>
                <div className="about-bg d-flex align-items-center main-container">
                    <div className="text-white fw-bold lead-heading">Our Blogs - DecHealth</div>
                </div>
                <div className="section-change">
                    <div className="main-container">
                        <div className="row row-cols-xl-3 row-cols-1 g-5">
                            {this.state.allBlogs.map((eachBlog, index) => {
                                return (
                                    <div className="col" key={index}>
                                        <BlogCard image={eachBlog.fields.blogImage.fields.file.url} description={eachBlog.fields.blogDescription} heading={eachBlog.fields.blogTitle} id={eachBlog.fields.blogId} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Blogs