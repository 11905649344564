import React, { useState } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarText } from 'reactstrap';

class Toolbar extends React.Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
      background: "bg-transparent"
    }
  }
  componentDidMount() {
    const changeNavColor = () => {
      if (window.scrollY > 0) {
        this.setState({ background: "bg-dark-o" })
      }
      else {
        this.setState({ background: "bg-transparent" })
      }
    }
    window.addEventListener("scroll", changeNavColor);
  }
  render() {
    const toggle = () => {
      this.setState({ isOpen: !this.state.isOpen }, () => {
        if (this.state.isOpen) {
          this.setState ({background: "bg-dark-o"})
        }
      })
    }

    return (
      <div>
        <Navbar className={`${this.state.background} py-5 main-container`} expand="lg" fixed='top' dark>
          <NavbarBrand className='heading light' href="/">HITESH PRIYANKA</NavbarBrand>
          <div>
            {!this.state.isOpen && <NavbarToggler onClick={toggle} />}
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="me-auto" navbar>
                <NavItem>
                  <NavLink className='menu-item' href="/">About Us</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className='menu-item' href="/solutions">Our Solutions</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className='menu-item' href="/blogs">DecHealth</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className='menu-item' href="/success-story">Success Story</NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </div>

        </Navbar>
      </div>
    );
  }
}

export default Toolbar;