import React from "react";

class SuccessStories extends React.Component {
    render () {
        return (
            <div>
                <div className="about-bg d-flex align-items-center main-container">
                    <div className="text-white fw-bold lead-heading">Success Stories</div>
                </div>
            </div>
        )
    }
}

export default SuccessStories