import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { createClient } from "contentful";
import withRouter from "./withRouter";

const client = createClient({
    space: `pmtorbc7c54z`,
    accessToken: `SGpR3nWjHeWci9XBcY_VofutGefIHdxzvPdgOpt91N4`
})

class FullBlog extends React.Component {
    constructor() {
        super();
        this.state = {
            blog: {},
            isLoading: true
        }
    }

    componentDidMount() {
        client.getEntries({ "fields.blogId": this.props.router.params.id, content_type: "decHealthBlogs" }).then(response => {
            this.setState({ blog: response.items[0], isLoading: false }, () => console.log(this.state.blog))
        }).catch(err => console.log(err.message))
    }
    render() {

        return (
            <div style={{ minHeight: "100vh" }}>
                <div className="about-bg d-flex align-items-center main-container">
                    <div className="text-white fw-bold lead-heading">Our Blogs - DecHealth</div>
                </div>
                <div className="main-container">
                    {this.state.isLoading ? null :
                        <div className="d-flex flex-lg-row flex-sm-col justify-content-between gap-5 my-3">
                            <div>
                                <img src={`https:${this.state.blog.fields?.blogImage.fields.file.url}`} alt="blogs" className="image-mobile" style={{ minWidth: "100%" }} />
                            </div>
                            <div>
                                <div className="p-3">
                                    <div className="dark fw-semibold fs-3 mb-2">{this.state.blog.fields?.blogTitle}</div>
                                    <div className="dark mb-3">{documentToReactComponents(this.state.blog.fields?.fullBlog)}</div>
                                </div>
                            </div>
                        </div>}
                </div>

            </div>
        )
    }
}

export default withRouter(FullBlog)